.body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
    flex: 1 0 auto;
}

.main{
    margin-top: 6em;
}

.icons{
    scale: 1.5;
}

.logotipo{    
    font-size: 12em;
    /* color: white; */
}

.inline-icon {
    vertical-align: bottom;
    font-size: 1.5rem !important;
 }